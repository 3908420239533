import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { IconButton } from '@bibliocommons/base-buttons';
import { StarOutline, Star } from '@bibliocommons/base-icons';
import { Link } from '@bibliocommons/base-links';
import { Dropdown, DropdownMenuItem, DropdownMenuDivider } from '@bibliocommons/base-dropdown';
import { cardShape } from '@bibliocommons/bc-prop-types';
import { UI_CONTAINER_TYPE } from 'app/constants/AnalyticsGa4Constants';
import { MY_EVENTS_BASE_URL } from 'app/constants/EventsConstants';
import { selectEventEntities, selectEventSeriesEntities } from 'app/selectors/EntitiesSelector';
import { trackEventInterested } from 'app/actions/AnalyticsGa4Actions';
import EventsActions from 'app/actions/EventsActions';
import { mapEventToEntity } from 'app/helpers/analytics/formatGA4Entity';

import './AddToInterestedEvents.scss';

export default function AddToInterestedEvents({
  eventId,
  seriesId,
  savedEvent,
  eventAnalytics,
  promos,
  card,
  handleCardEventInterestedClick
}) {
  const dispatch = useDispatch();
  const event = useSelector(selectEventEntities)?.get(eventId);
  const series = useSelector(selectEventSeriesEntities)?.get(seriesId);
  const interested = savedEvent?.get('eventIds')?.includes(eventId) ?? false;

  function addToInterestedEvents() {
    dispatch(EventsActions.addToInterestedEvents({ eventId, seriesId }));

    if (promos) {
      handleCardEventInterestedClick(card);
      return;
    }
    const search = {};
    let eventEntity = {};
    if (event) {
      eventEntity = mapEventToEntity({ event, series });
    }
    const ui = {
      ui_container_type: UI_CONTAINER_TYPE.ITEM,
      ui_content_layout: 'medium',
      ui_content_page: eventAnalytics?.uiContentPage,
      ui_content_total_count: eventAnalytics?.uiContentTotalCount
    };
    if (eventAnalytics?.searchQuery) {
      search.search_query = eventAnalytics?.searchQuery;
      search.search_origin = 'user-query';
      search.search_type = 'keyword';
    }
    dispatch(
      trackEventInterested({
        event: eventEntity,
        search,
        ui
      })
    );
  }

  function removeFromInterestedEvents() {
    dispatch(
      EventsActions.removeFromInterestedEvents({ eventId, seriesId, registrationId: savedEvent?.get('registration') })
    );
  }

  function renderDropdownLabel() {
    return (
      <span className="dropdown-label">
        <Star />
      </span>
    );
  }

  function renderSavedEventDropdown() {
    return (
      <Dropdown
        right
        dataKey="interested-event-dropdown"
        aria-label="Interested Event"
        buttonTag={IconButton}
        renderLabel={renderDropdownLabel}
      >
        <DropdownMenuItem
          item={Immutable.Map({
            label: <FormattedMessage id="remove_from_my_events" />,
            value: 'remove-from-my-events'
          })}
          handleClick={removeFromInterestedEvents}
        />
        <DropdownMenuDivider />
        <DropdownMenuItem>
          {/* eslint-disable-next-line */}
          <Link href={`${MY_EVENTS_BASE_URL}/upcoming/interested`} dataKey={`my_events${eventId}`}>
            <FormattedMessage id="go_to_my_events" />
          </Link>
        </DropdownMenuItem>
      </Dropdown>
    );
  }

  return (
    <div className="cp-add-to-interested-events">
      {interested ? (
        renderSavedEventDropdown()
      ) : (
        <IconButton
          dataKey="add-to-interested-events-button"
          className="star-icon-button"
          handleClick={addToInterestedEvents}
          aria-label="Save Event as Interested"
        >
          <StarOutline />
        </IconButton>
      )}
    </div>
  );
}

AddToInterestedEvents.propTypes = {
  eventId: PropTypes.string.isRequired,
  seriesId: PropTypes.string,
  savedEvent: ImmutablePropTypes.mapContains({
    registrationId: PropTypes.string,
    eventIds: PropTypes.object
  }),
  eventAnalytics: PropTypes.shape({
    searchQuery: PropTypes.string,
    uiContentPage: PropTypes.number,
    uiContentTotalCount: PropTypes.number,
    uiContentSort: PropTypes.string
  }),
  promos: PropTypes.bool,
  card: cardShape,
  handleCardEventInterestedClick: PropTypes.func
};
