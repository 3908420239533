import React from 'react';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { useRouter } from '@bibliocommons/utils-routing';
import { TextButton } from '@bibliocommons/base-buttons';
import { Link } from '@bibliocommons/base-links';
import { LinkedPill } from '@bibliocommons/pill';
import Popover from '@bibliocommons/popover';
import { EVENTS_URL, TAXONOMY_DISPLAY_TYPE } from 'app/constants/EventsConstants';

import './EventTaxonomies.scss';

export default function EventTaxonomies({ program, types, audiences, languages, displayType, displayAll = false }) {
  const router = useRouter();

  function renderTaxonomy(fieldId, taxonomy) {
    const taxonomyId = taxonomy.get('id');
    const taxonomyName = taxonomy.get('name');
    const href = `${EVENTS_URL}?${fieldId}=${taxonomyId}`;

    switch (displayType) {
      case TAXONOMY_DISPLAY_TYPE.PILL: {
        return (
          <li key={taxonomyId}>
            <LinkedPill
              className="taxonomy-link"
              dataKey="event-taxonomy-link"
              href={href}
              rel="noopener noreferrer"
            >
              {taxonomyName}
            </LinkedPill>
          </li>
        );
      }

      case TAXONOMY_DISPLAY_TYPE.LINK:
      default: {
        return (
          <li key={taxonomyId}>
            <Link
              preventDefault
              className="taxonomy-link"
              dataKey="event-taxonomy-link"
              href={href}
              aria-current={!!router.query[fieldId]?.includes(taxonomyId)}
              handleClick={() => router.push(href)}
            >
              <span aria-hidden>{taxonomyName}</span>
              <ScreenReaderMessage>
                <FormattedMessage id="sr_taxonomy" values={{ taxonomyName }} />
              </ScreenReaderMessage>
            </Link>
          </li>
        );
      }
    }
  }

  const allTaxonomies = Immutable.List([
    program ? renderTaxonomy('programs', program) : null,
    types.map(type => renderTaxonomy('types', type)),
    audiences.map(audience => renderTaxonomy('audiences', audience)),
    languages.map(language => renderTaxonomy('languages', language))
  ])
    .flatten()
    .filter(taxonomy => !!taxonomy);

  const displayedTaxonomies = displayAll ? allTaxonomies : allTaxonomies.slice(0, 5);
  const moreTaxonomies = displayAll ? false : allTaxonomies.slice(5, allTaxonomies.size - 1);

  function renderPopoverContent() {
    return (
      <div className="popover-contents">
        <ul>{moreTaxonomies}</ul>
      </div>
    );
  }

  return (
    !!allTaxonomies.size && (
      <div className="cp-event-taxonomies">
        <ul>{displayedTaxonomies}</ul>
        {!!moreTaxonomies?.size && (
          <Popover dataKey="more-taxonomies-container" renderContent={renderPopoverContent}>
            <TextButton className="more-taxonomies" dataKey="more-taxonomies-popover">
              <span aria-hidden>
                <FormattedMessage id="more_taxonomies" values={{ count: moreTaxonomies.size }} />
              </span>
              <ScreenReaderMessage>
                <FormattedMessage id="sr_more_taxonomies" values={{ count: moreTaxonomies.size }} />
              </ScreenReaderMessage>
            </TextButton>
          </Popover>
        )}
      </div>
    )
  );
}

EventTaxonomies.propTypes = {
  program: ImmutablePropTypes.map,
  types: ImmutablePropTypes.list.isRequired,
  audiences: ImmutablePropTypes.list.isRequired,
  languages: ImmutablePropTypes.list.isRequired,
  displayType: PropTypes.oneOf([TAXONOMY_DISPLAY_TYPE.LINK, TAXONOMY_DISPLAY_TYPE.PILL]),
  displayAll: PropTypes.bool
};
