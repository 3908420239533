import React from 'react';
import { stripTags, truncate } from '@bibliocommons/utils-html';
import { eventShape } from 'app/shapes/events';

export default function EventDescription({ event }) {
  const strippedDesc = stripTags(event.getIn(['definition', 'description'], ''), '<p>');
  const description = truncate(strippedDesc, 240);

  return (
    <div className="cp-event-description">
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}

EventDescription.propTypes = {
  event: eventShape.isRequired
};
